import { tns } from "tiny-slider/src/tiny-slider";
window.tns = tns;
import AOS from "aos";
import "aos/dist/aos.css";
// let options = {
//     el: document.querySelector('[data-scroll-container]'),
//     smooth: true,
//     getSpeed: true,
//     getDirection: true,
// };
// var scroll = new LocomotiveScroll(options);

// AOS.init({
//     duration: 2000,
//     easing: "ease-out-back",
// });
// window.addEventListener("load", AOS.refresh);

// Select + Dropdow
const dropdownItems = document.querySelectorAll(".option");
dropdownItems.forEach((item) => {
    item.addEventListener("click", function () {
        const optionText = this.querySelector(".option-text").innerHTML;
        const dropdown = this.closest(".dropdown");
        const dropdownToggle = dropdown.querySelector(".drop-button");
        const sText = dropdownToggle.querySelector(".sBtn-text");
        sText.innerHTML = optionText;
    });
});


// Date Picker
$(function () {
    const datePickerIds = [
        "datepicker",
        "datepicker1",
        "datepicker2", //Add datepicker id here
    ];
    datePickerIds.forEach((element) => {
        $("#" + element).datepicker({
            dateFormat: "dd-mm-yy",
            duration: "fast",
        });
    });
});
// Date Picker End

let scrollToTopBtn = document.querySelector(".scrollToTopBtn");
if (scrollToTopBtn) {
    let rootElement = document.documentElement;

    function handleScroll() {
        // Do something on scroll - 0.15 is the percentage the page has to scroll before the button appears
        // This can be changed - experiment
        var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
        if (rootElement.scrollTop / scrollTotal > 0.15) {
            // Show button
            scrollToTopBtn.classList.add("showBtn");
        } else {
            // Hide button
            scrollToTopBtn.classList.remove("showBtn");
        }
    }

    function scrollToTop() {
        // Scroll to top logic
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);
}

